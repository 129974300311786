
















































import Vue from 'vue';

import { mapActions, mapState } from 'vuex';

import { subscriptionActions } from '@/modules/subscription/store/names';
import { ComposedState } from '@/store/general.types';

export default Vue.extend({
    name: 'SubscriptionsPage',

    data() {
        return {
            loading: true,

            number: '',

            limit: 5,
            page: 1,

            count: 1,

            headers: [
                {
                    text: 'currency',
                    align: 'start',
                    sortable: false,
                    value: 'currency',
                },
                {
                    text: 'id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'monthly_price',
                    align: 'start',
                    sortable: false,
                    value: 'monthly_price',
                },
                {
                    text: 'num_months',
                    align: 'start',
                    sortable: false,
                    value: 'num_months',
                },
                {
                    text: 'num_years',
                    align: 'center',
                    sortable: false,
                    value: 'num_years',
                },
                {
                    text: 'price',
                    align: 'center',
                    sortable: false,
                    value: 'price',
                },
                {
                    text: 'limit dreams',
                    align: 'center',
                    sortable: false,
                    value: 'limit_dreams',
                },
            ],
        };
    },

    computed: {
        ...mapState<ComposedState>({
            subscribes: (state: ComposedState) => state.subscription.subscribesData,
        }),
    },

    async mounted() {
        await this.getSubscribes();
        this.loading = false;
    },

    methods: {
        ...mapActions({
            getSubscribes: subscriptionActions.getSubscribesApi,
        }),

        async clickRow(data) {
            this.$router.push({ 
                name: 'SubscriptionItem', 
                params: { id: data.id },
            });
        },

        createSubscription() {
            this.$router.push({ name: 'SubscriptionCreate' });
        },
    },
});
